<template>
    <div class="container-box">
        <el-card class="box-card-bottom">
            <div class="step" style="margin-bottom: 10px;">
                                <span @click="nextStep">下一步</span>
                </div>
                <el-tabs v-model="showTab" type="border-card" class="demo-tabs">
                    <el-tab-pane label="信息通信工程实践" name="1">
                        <el-table 
                        v-loading="loading" 
                        border 
                        :scrollbar-always-on="true" 
                        :data="tableData"
                        :row-key="record=>record.team_id"
                            @selection-change="handleSelectionChange" 
                            style="width: 100%;border-radius: 0.05rem;">
                                <el-table-column type="selection" width="55"> </el-table-column>
                                <el-table-column type="index" sortable label="序号" width="60" />
                                <el-table-column prop="school" sortable label="学校全称" width="120" />
                                <el-table-column prop="province" sortable label="省份" />
                                <el-table-column prop="group" sortable label="组别" width="180" />

                                <el-table-column prop="name1" sortable label="参赛选手1" width="180" />
                                <el-table-column prop="id_num1" sortable label="身份证号" width="180" />
                                <el-table-column prop="phone1" sortable label="手机号" width="180" />
                                <el-table-column prop="mail1" sortable label="邮箱" width="180" />
                                <el-table-column prop="sex1" sortable label="性别" width="180" />
                                <el-table-column prop="grade1" sortable label="学级" width="180" />
                                <el-table-column prop="profession1" sortable label="专业名称" width="180" />
                                <el-table-column prop="name2" sortable label="参赛选手2" width="180" />
                                <el-table-column prop="id_num2" sortable label="身份证号" width="180" />
                                <el-table-column prop="phone2" sortable label="手机号" width="180" />
                                <el-table-column prop="mail2" sortable label="邮箱" width="180" />
                                <el-table-column prop="sex2" sortable label="性别" width="180" />
                                <el-table-column prop="grade2" sortable label="学级" width="180" />
                                <el-table-column prop="profession2" sortable label="专业名称" width="180" />
                                <el-table-column prop="name3" sortable label="指导教师1" width="180" />
                                <el-table-column prop="phone3" sortable label="手机号" width="180" />
                                <el-table-column prop="mail3" sortable label="邮箱" width="180" />
                                <el-table-column prop="name4" sortable label="指导教师2" width="180" />
                                <el-table-column prop="phone4" sortable label="手机号" width="180" />
                                <el-table-column prop="mail4" sortable label="邮箱" width="180" />
                                <el-table-column prop="name5" sortable label="带队教师" width="180" />
                                <el-table-column prop="phone5" sortable label="手机号" width="180" />
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="产教融合5G+创新应用设计赛" name="2">
                        <el-table v-loading="Loading_5G" border :scrollbar-always-on="true"
                            :data="tableData_5G"
                            :row-key="record=>record.team_id"
                            @expand-change="handleExpendRow"
                            @selection-change="handleSelectionChange_5G"
                            style="width: 100%;border-radius: 0.05rem;">
                            <el-table-column type="selection" width="55"></el-table-column>
                            <el-table-column type="expand" width="120">
                                <template #default="props">
                                    <el-table :data="props.row.members">
                                        <el-table-column prop="role" label="角色" width="80"></el-table-column>
                                        <el-table-column prop="name" label="姓名" width="120"></el-table-column>
                                        <el-table-column prop="id_num" label="身份证号"></el-table-column>
                                        <el-table-column prop="phone" label="手机号"></el-table-column>
                                    </el-table>
                                </template>
                            </el-table-column>
                            <el-table-column prop="school" sortable label="学校全称"/>
                            <el-table-column prop="province" sortable label="学校所在省份"/>
                            <el-table-column prop="name" sortable label="队名" width="180" />
                            <el-table-column prop="group" sortable label="参赛组别" width="180" />
                        </el-table>
                    <div class="pagination-box">
                        <el-pagination @size-change="handleInnovateSizeChange" @current-change="handleInnovateCurrentChange"
                            v-model:current-page="this.innovatePage" v-model:page-size="this.innovatePageSize"
                            layout="total, prev, pager, next" :total="this.innovateTotal">
                        </el-pagination>
                    </div>
                    </el-tab-pane>
                    <el-tab-pane label="ICT基础通识赛" name="3">
                        <el-table 
                        v-loading="loading" 
                        border 
                        :scrollbar-always-on="true" 
                        :data="tableData_ict"
                        :row-key="record=>record.team_id"
                            @selection-change="ictHandleSelectionChange" 
                            style="width: 100%;border-radius: 0.05rem;">
                                <el-table-column type="selection" width="55"> </el-table-column>
                                <el-table-column type="index" sortable label="序号" width="60" />
                                <el-table-column prop="school" sortable label="学校全称" width="120" />
                                <el-table-column prop="province" sortable label="省份" />
                                <el-table-column prop="name1" sortable label="参赛选手1" width="180" />
                                <el-table-column prop="id_num1" sortable label="身份证号" width="180" />
                                <el-table-column prop="phone1" sortable label="手机号" width="180" />
                                <el-table-column prop="mail1" sortable label="邮箱" width="180" />
                                <el-table-column prop="sex1" sortable label="性别" width="180" />
                                <el-table-column prop="grade1" sortable label="学级" width="180" />
                                <el-table-column prop="profession1" sortable label="专业名称" width="180" />
                                <el-table-column prop="name2" sortable label="参赛选手2" width="180" />
                                <el-table-column prop="id_num2" sortable label="身份证号" width="180" />
                                <el-table-column prop="phone2" sortable label="手机号" width="180" />
                                <el-table-column prop="mail2" sortable label="邮箱" width="180" />
                                <el-table-column prop="sex2" sortable label="性别" width="180" />
                                <el-table-column prop="grade2" sortable label="学级" width="180" />
                                <el-table-column prop="profession2" sortable label="专业名称" width="180" />
                                <el-table-column prop="name3" sortable label="指导教师1" width="180" />
                                <el-table-column prop="phone3" sortable label="手机号" width="180" />
                                <el-table-column prop="mail3" sortable label="邮箱" width="180" />
                                <el-table-column prop="name4" sortable label="指导教师2" width="180" />
                                <el-table-column prop="phone4" sortable label="手机号" width="180" />
                                <el-table-column prop="mail4" sortable label="邮箱" width="180" />
                                <el-table-column prop="name5" sortable label="带队教师" width="180" />
                                <el-table-column prop="phone5" sortable label="手机号" width="180" />
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
           
        </el-card>
    </div>
</template>
<script>
import { userInfo } from "api/apis.js";
import { ElMessage } from 'element-plus';
export default {
    components: {
    },
    data() {
        return {
            loading: false,
            Loading_5G:false,
            // tabPosition:'left',
            tableData: [],
            tableData_5G:[],
            tableData_ict:[],
            multipleSelection:[],
            multipleSelectionIct:[],
            innovateTotal: 0,//总数居
            innovatePage: 1,// 当前多少页
            innovatePageSize: 10,//当前显示多少条数,
            team_ids:[],
            team_ids_5G:[],
            team_ids_ict:[],
            nums:0,
            team_nums:0,
            ictNums:0,
            ict_team_nums:0,
            _gnums:0,
            teamINums:0,
            palyers_nums:0,
            t:0,
            path:'',
            showTab:'1',
            type1:[],
            type2:[],
            type3:[],
            track1:[],
            track2:[],
            track3:[],
            group1:[],
            group2:[],
            group3:[],
        }
    },
    created() {
        this.init_Info()
    },
    methods: {
        submitForm(formEl) {
            if (!formEl) return
            this.$refs[formEl].validate((valid) => {
                if (valid) {
                    this.tableData = []
                    this.getUserNumber()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //初始化
        init_Info() {
            this.getUserNumber();
            this.getUserInnovateNumber();
            this.getIctUserNumber()
        },
        //勾选工程实践赛
        handleSelectionChange (val){
            this.team_ids = [];
            this.multipleSelection = val
            let groupList = [];
            let typeList = [];
            let trackList = [];
            val.forEach(item=>{
                this.team_ids.push(item.team_id1)
                groupList.push(item.group)
                typeList.push(item.competition_type_li)
                trackList.push(item.track_li)
            })
            this.group1 = groupList;
            this.type1 = typeList;
            this.track1 = trackList;
        },
        //勾选5G创新赛
        handleSelectionChange_5G(val) {
            let team_ids_5=[]
            let palyers_nums=0;
            this.t = val.length;
            let groupList = [];
            let typeList = [];
            let trackList = [];
            val.forEach(i=> {
                groupList.push(i.group)
                typeList.push(i.type)
                trackList.push(i.track)
                team_ids_5.push(i.team_id);
                this.$nextTick(()=> {
                    this.team_ids_5G = team_ids_5;
                })
                i.members.forEach(m => {
                    if(m.role != '指导教师') {
                        palyers_nums =palyers_nums+1
                        this.$nextTick(()=> {
                            this.palyers_nums = palyers_nums;
                        })
                    }
                })
            })
            this.group2 = groupList;
            this.type2 = typeList;
            this.track2 = trackList;
        },
        //ICT基础通识赛
        ictHandleSelectionChange (val){
            this.team_ids_ict = [];
            this.multipleSelectionIct = val
            let groupList = [];
            let typeList = [];
            let trackList = [];
            val.forEach(item=>{
                this.team_ids_ict.push(item.team_id1)
                groupList.push(item.group)
                typeList.push(item.competition_type_li)
                trackList.push(item.track_li)
            })
            this.group3 = groupList;
            this.type3 = typeList;
            this.track3 = trackList;
        },
        //信息通信工程实践
        getUserNumber() {
            let uid = this.$store.state.User.user_id
            console.log(this.$store.state);
            let dataobj = new FormData();
            dataobj.append("user_id", uid);
            // dataobj.append("page", this.page);//当前页数
            // dataobj.append("limit", this.pageSize);//一页显示多少条
            dataobj.append("status", '0');//状态 0:未审核 1:已审核
            dataobj.append("track",'学生');
            dataobj.append("type",'国赛');
            this.loading = true;
            this.tableData = []
            this.team_ids = [];
            userInfo.selsetUser(dataobj).then(res => {
                this.nums= 0;
                this.loading = false;
                const { count, data } = res;
                this.total = count || 0;
                console.log(JSON.parse(data));
                if(JSON.parse(data).length > 0 ){
                    let resData = JSON.parse(data)[0];
                    // this.type = resData.type;
                    // this.track = resData.track;
                    // this.group = resData.group;
                }
                let totalNums = [] //计算队员总数
                this.team_nums = +Number(JSON.parse(data).length);
                JSON.parse(data).forEach(m=> {
                    totalNums.push(...m.players)
                })
                this.nums = totalNums.length;
                
                JSON.parse(data).forEach((ele, index) => {
                    let obj = {}
                    this.team_ids.push(ele.team_id) //保存团队id
                    obj.group = ele.group
                    obj.province = ele.province
                    obj.school = ele.school
                    obj.competition_type_li = ele.type
                    obj.track_li = ele.track
                    //队员信息
                    ele.players.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '1'] = item[key]
                            }
                        } else if (index === 1) {
                            for (let key in item) {
                                obj[key + '2'] = item[key]
                            }
                        }
                    })
                    //教师信息
                    ele.teachers_adviser.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '3'] = item[key]
                            }
                        } else if (index === 1) {
                            for (let key in item) {
                                obj[key + '4'] = item[key]
                            }
                        } else if (index === 2) {
                            obj.tphone3 = item.phone
                            obj.tmail3 = item.mail
                        }

                    })
                    //带队教师信息
                    ele.teachers_leader.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '5'] = item[key]
                            }
                        } 

                    })
                    this.tableData.push(obj);
                })

            })
        },
        //5G+创新应用实践赛
        getUserInnovateNumber() {
            let uid = this.$store.state.User.user_id
            let dataobj = new FormData();
            dataobj.append("user_id", uid);
            dataobj.append("page", 1);//当前页数
            // dataobj.append("limit", 10);//一页显示多少条
            // dataobj.append("search_name", this.formInline.name);//姓名
            dataobj.append("status", '0');//状态 0:未审核 1:已审核
            dataobj.append("track", '创新');
            dataobj.append("type", '国赛');
            this.Loading_5G = true;
            userInfo.selsetUser(dataobj).then(res => {
                this.Loading_5G = false;
                const { count, data } = res;
                console.log(JSON.parse(data))
                this.innovateTotal = count;
                this.tableData_5G = JSON.parse(data);
            });
        },
        //ict
        getIctUserNumber() {
            let uid = this.$store.state.User.user_id
            let dataobj = new FormData();
            dataobj.append("user_id", uid);
            // dataobj.append("page", this.page);//当前页数
            // dataobj.append("limit", this.pageSize);//一页显示多少条
            dataobj.append("status", '0');//状态 0:未审核 1:已审核
            dataobj.append("track",'ICT基础通识');
            dataobj.append("type",'国赛');
            this.loading = true;
            this.tableData_ict = []
            this.team_ids_ict = [];
            userInfo.selsetUser(dataobj).then(res => {
                this.ictNums= 0;
                this.loading = false;
                const { count, data } = res;
                this.total = count || 0;
                console.log(JSON.parse(data));
                if(JSON.parse(data).length > 0 ){
                    let resData = JSON.parse(data)[0];
                    // this.type = resData.type;
                    // this.track = resData.track;
                    // this.group = resData.group;
                }
                let totalNums = [] //计算队员总数
                this.ict_team_nums = +Number(JSON.parse(data).length);
                JSON.parse(data).forEach(m=> {
                    totalNums.push(...m.players)
                })
                this.ictNums = totalNums.length;
                
                JSON.parse(data).forEach((ele, index) => {
                    let obj = {}
                    this.team_ids_ict.push(ele.team_id) //保存团队id
                    obj.group = ele.group
                    obj.province = ele.province
                    obj.school = ele.school
                    obj.competition_type_li = ele.type
                    obj.track_li = ele.track
                    //队员信息
                    ele.players.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '1'] = item[key]
                            }
                        } else if (index === 1) {
                            for (let key in item) {
                                obj[key + '2'] = item[key]
                            }
                        }
                    })
                    //教师信息
                    ele.teachers_adviser.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '3'] = item[key]
                            }
                        } else if (index === 1) {
                            for (let key in item) {
                                obj[key + '4'] = item[key]
                            }
                        } else if (index === 2) {
                            obj.tphone3 = item.phone
                            obj.tmail3 = item.mail
                        }

                    })
                    //带队教师信息
                    ele.teachers_leader.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '5'] = item[key]
                            }
                        } 

                    })
                    this.tableData_ict.push(obj);
                })

            })
        },


        handleExpendRow(row, expandedRows) {
            console.log(row, expandedRows);
        },
        getTeamList(item) {
                let leveListP = [];
                let leveListT = [];
                let acc = []
                leveListP.push(...item.players);
                leveListT.push(...item.teachers);
                    //队员信息
                    leveListP=ele.players.map(accItem=> {
                        return {
                            school:ele.school,
                            province:ele.province,
                            name:ele.name,
                            role:accItem.role,
                            name1:accItem.name,
                            id_num:accItem.id_num,
                            phone:accItem.phone,
                            mail:accItem.mail,
                            sex:accItem.sex,
                            grade:accItem.grade,
                            profession:accItem.profession
                        }
                    });
                    //教师信息
                    leveListT=ele.teachers.map(accItem=> {
                        return {
                            school:ele.school,
                            province:ele.province,
                            name:ele.name,
                            role:accItem.role,
                            name1:accItem.name,
                            id_num:accItem.id_num,
                            phone:accItem.phone,
                            mail:accItem.mail,
                            sex:accItem.sex,
                            grade:accItem.grade,
                            profession:accItem.profession
                        }
                    })
                    acc = leveListP.concat(...leveListT)
                    return acc.map(accItem=> {
                        return {
                            school:item.school,
                            province:item.province,
                            name:item.name,
                            role:accItem.role,
                            name1:accItem.name,
                            id_num:accItem.id_num,
                            phone:accItem.phone,
                            mail:accItem.mail,
                            sex:accItem.sex,
                            grade:accItem.grade,
                            profession:accItem.profession
                        }
                    })
        },
        //下一步
        nextStep() {
            if(this.multipleSelection.length === 0) {
                this.team_ids = [];
            }
            if(this.multipleSelectionIct.length === 0) {
                this.team_ids_ict = [];
            }
            let team_nums = this.multipleSelection.length+Number(this.t)+this.multipleSelectionIct.length;//组数
            let TOTAL_TEAM_IDS= this.team_ids.concat(...this.team_ids_5G,...this.team_ids_ict) //组id
            console.log(TOTAL_TEAM_IDS);
            debugger
            let totalObj= {
                    nums:+this.multipleSelection.length*2+Number(this.palyers_nums)+this.multipleSelectionIct.length*2,
                    team_nums: team_nums,
                }
            if(team_nums===0) {
                ElMessage({
                    message: "请选择报名小组",
                    type: "error",
                    });
                    return
            }   
                    let group_list = [...new Set(this.group1.concat(this.group2).concat(this.group3))];
                    let competition_type_li = [...new Set(this.type1.concat(this.type2).concat(this.type3))];
                    let track_li = [...new Set(this.track1.concat(this.track2).concat(this.track3))];
                    console.log(group_list);
                    this.$router.push(
                    { 
                        path: '/ViewDetails', 
                        query:  
                        { 
                            'total_Obj': JSON.stringify(totalObj), 
                            team_ids: TOTAL_TEAM_IDS,
                            engineeringPractice:JSON.stringify(this.tableData),
                            innovation:this.tableData_5G,
                            group_li:group_list,
                            competition_type_li:competition_type_li,
                            track_li:track_li,
                        }
                    }) 
        },
    }
}
</script>
<style lang="scss" scoped>
.container-box {
    width: 95%;
    margin: auto;
}
.box-card-bottom {
    // overflow-y: scroll;
}

.formWeight {
    display: flex;
}

.demo-form-inline {
    flex: 3;
}
.decorationBox{

    color: blue;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.right-box {
    display: flex;
    justify-content: left;
    flex: 2
}

.dt_title {
    display: inline-block;
    color: #3D3D3D;
    font-size: 0.22rem;
    margin-right: 0.2rem;
}

.L_btn {
    width: 1.16rem;
    height: 0.32rem;
    border: 0;
    background-color: #C45454;
    border-radius: 0.04rem;
    font-size: 0.14rem;
    color: white;
    // margin-right: 0.2rem;
    cursor: pointer;
}

.R_btn {}

.pagination-box {
    display: flex;
    margin-top: 0.15rem;
}

.el-pagination {
    flex: 2;
}

.step {
    flex: 1;
    display: flex;
    justify-content: right;

    span {
        display: block;
        width: 1.71rem;
        height: 0.45rem;
        font-size: 0.24rem;
        text-align: center;
        line-height: 0.45rem;
        color: white;
        background-color: #436E8A;
        border-radius: 0.04rem;
        cursor: pointer;
    }
}
</style>